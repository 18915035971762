<template>
  <b-modal
    id="support"
    hide-footer
    :title="$t('dd.donate')"
    title-tag="h2"
    :visible="/^#support/.test($parent.hash)"
  >
    <b-form
      id="form_don"
      class="px-4"
      @submit="payRedirection"
    >
      <!-- Step 1 #howMuch ---------------------------------------------->
      <div id="howmuch">
        <div
          class="row mx-n3 amount"
        >
          <div class="col-12">
            <label
              for="don"
              class="sr-only"
              v-html="$t('dd.amount', { n: 42 })"
            ></label>
            <b-input-group
              class="selected"
              prepend="€"
            >
              <b-form-input
                id="don"
                v-model.number="form.don"
                min="1"
                max="100000"
                step="any"
                pattern="/^\d+([\.\,]\d{1,2})?$/"
                :placeholder="$t('dd.amount', { n: 42 })"
                type="number"
              />
            </b-input-group>
          </div>
        </div>

        <!-- Email -->
        <b-col class="px-0">
          <b-input-group class="mb-2">
            <template v-slot:prepend>
              <b-input-group-text>
                <i class="far fa-envelope fa-fw"></i>
                <label
                  for="email"
                  class="sr-only"
                  v-html="$t('sfs.form.step2.email')"
                ></label>
              </b-input-group-text>
            </template>
            <b-form-input
              id="email"
              v-model="form.email"
              :placeholder="$t('sfs.form.step2.email')"
              required
              :title="form.society
                ? $t('sfs.form.step2.society_email_ex', '-t')
                : $t('sfs.form.step2.email_ex', '-t')"
              type="email"
              :state="form.ok.email"
              @focusout="check('email')"
            />
          </b-input-group>
        </b-col>

        <!-- Nickname -->
        <b-col class="px-0">
          <b-input-group class="mb-2">
            <template v-slot:prepend>
              <b-input-group-text>
                <i
                  v-show="form.state.anonymous"
                  class="fas fa-user-secret fa-fw"
                ></i>
                <i
                  v-show="!form.state.anonymous"
                  class="fas fa-user fa-fw"
                ></i>
                <label
                  for="nickname"
                  class="sr-only"
                  v-html="$t('sfs.form.step2.nickname')"
                ></label>
              </b-input-group-text>
            </template>
            <b-form-input
              id="nickname"
              v-model="form.nickname"
              :placeholder="$t('sfs.form.step2.nickname')"
              :title="$t('sfs.form.step2.nickname_ex', '-t')"
              :readonly="form.state.anonymous"
              required
              :state="form.ok.nickname"
              @focusout="check('nickname')"
            />
          </b-input-group>
        </b-col>
        <b-col class="px-0">
          <!-- Anonymous ? -->
          <b-form-checkbox
            v-model="form.state.anonymous"
            name="anonymous"
            switch
            class="fc-g7"
            @change="anonymize()"
          >
            <span v-html="$t('sfs.form.step1.anonymous')"></span>
          </b-form-checkbox>
        </b-col>
        <b-col class="px-0">
          <!-- Receipt ? -->
          <b-form-checkbox
            v-model="form.state.receipt"
            name="receipt"
            switch
            class="fc-g7"
            @change="fillReceiptFields()"
          >
            <span v-html="$t('sfs.form.step1.receipt')"></span>
          </b-form-checkbox>
        </b-col>
      </div>

      <!-- Step 2 #whoAreYou -------------------------------------------->
      <div
        id="whoAreYou"
        class="my-2"
      >
        <b-card
          v-show="form.state.receipt"
          class="mb-4 pt-2 pb-4 px-3 mx-0"
          no-body
        >
          <h3 class="text-right mb-n4">
            <span
              class="sr-only"
              v-html="$t('sfs.form.step2.title')"
            ></span>
            <button
              id="privacy"
              class="btn p-0 btn-lg"
              type="button"
            >
              <i class="fas fa-question-circle fa-circle-question fc-g7"></i>
              <span
                class="sr-only"
                v-html="$t('sfs.form.step2.private')"
              ></span>
            </button>
            <b-popover
              target="privacy"
              placement="leftbottom"
              :triggers="['click', 'hover']"
            >
              <div v-html="$t('sfs.form.step2.private_tip')"></div>
            </b-popover>
          </h3>

          <!-- Individual / Society -->
          <fieldset class="form-group">
            <legend
              class="sr-only"
              v-html="$t('sfs.form.step2.type')"
            ></legend>
            <b-row>
              <b-col
                cols="12"
                aria-hidden="true"
                class="col-form-label"
              >
                <div v-html="$t('sfs.form.step2.type')"></div>
              </b-col>

              <b-col class="px-4">
                <b-radio-group
                  v-model="form.society"
                  buttons
                  button-variant=" text-uppercase fc-g7 py-2"
                  size="sm"
                  @change="form.firstname = ''"
                >
                  <b-radio
                    :value="false"
                    class="text-decoration-none"
                  >
                    <i class="far fa-address-card"></i>
                    <span v-html="$t('sfs.form.step2.part')"></span>
                  </b-radio>
                  <b-radio
                    :value="true"
                    class="text-decoration-none"
                  >
                    <span
                      style="cursor: help"
                      :title="$t('sfs.form.step2.corp_tip')"
                    >
                      <i class="fas fa-university fa-building-columns"></i>
                      <span v-html="$t('sfs.form.step2.corp')"></span>
                    </span>
                  </b-radio>
                </b-radio-group>
              </b-col>
            </b-row>

            <p
              v-show="form.society"
              id="partenariat"
              class="fc-g7 ml-2 mb-0"
              v-html="$t('sfs.form.step2.mecenat')"
            ></p>
          </fieldset>

          <div
            class="mt-2"
          >
            <!-- Lastname / Firstname -->
            <b-col
              v-if="!form.society"
              class="px-0 px-md-3"
            >
              <b-input-group>
                <template v-slot:prepend>
                  <b-input-group-text>
                    <i class="fas fa-address-card fa-fw"></i>
                    <label
                      for="lastname"
                      class="sr-only"
                      v-html="$t('sfs.form.step2.lastname')"
                    ></label>
                    <label
                      for="firstname"
                      class="sr-only"
                      v-html="$t('sfs.form.step2.firstname')"
                    ></label>
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="lastname"
                  v-model="form.lastname"
                  :placeholder="$t('sfs.form.step2.lastname')"
                  :title="$t('sfs.form.step2.lastname_ex', '-t')"
                  :required="form.state.receipt ? 'required' : false"
                  :state="form.ok.lastname"
                  @focusout="check('lastname')"
                />
                <b-form-input
                  id="firstname"
                  v-model="form.firstname"
                  :placeholder="$t('sfs.form.step2.firstname')"
                  :title="$t('sfs.form.step2.firstname_ex', '-t')"
                  :required="form.state.receipt ? 'required' : false"
                  :state="form.ok.firstname"
                  @focusout="check('firstname')"
                />
              </b-input-group>
            </b-col>
            <!-- Society -->
            <b-col
              v-else
              class="px-0 px-md-3"
            >
              <b-input-group>
                <template v-slot:prepend>
                  <b-input-group-text>
                    <i class="fas fa-university fa-building-columns fa-fw"></i>
                    <label
                      for="lastname"
                      class="sr-only"
                      v-html="$t('sfs.form.step2.society')"
                    ></label>
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="lastname"
                  v-model="form.lastname"
                  :placeholder="$t('sfs.form.step2.society')"
                  :title="$t('sfs.form.step2.society_ex', '-t')"
                  :required="form.state.receipt ? 'required' : false"
                  :state="form.ok.lastname"
                  @focusout="check('lastname')"
                />
              </b-input-group>
              <b-input-group>
                <template v-slot:prepend>
                  <b-input-group-text>
                    <label
                      for="siren"
                      class="sr-only"
                      v-html="$t('sfs.form.step2.siren')"
                    ></label>
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="siren"
                  v-model="form.firstname"
                  :placeholder="$t('sfs.form.step2.siren')"
                  :title="$t('sfs.form.step2.siren_ex', '-t')"
                />
              </b-input-group>
              <b-input-group>
                <template v-slot:prepend>
                  <b-input-group-text>
                    <i class="fas fa-gavel fa-fw"></i>
                    <label
                      for="legalStatus"
                      class="sr-only"
                      v-html="$t('sfs.form.step2.legalStatus')"
                    ></label>
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="legalStatus"
                  v-model="form.legalStatus"
                  :placeholder="$t('sfs.form.step2.legalStatus')"
                  :title="$t('sfs.form.step2.legalStatus_ex', '-t')"
                />
              </b-input-group>
            </b-col>

            <!-- Address -->
            <b-col class="px-0 px-md-3">
              <b-input-group>
                <template v-slot:prepend>
                  <b-input-group-text>
                    <i class="fas fa-map-marker fa-location-pin fa-fw"></i>
                    <label
                      for="address1"
                      class="sr-only"
                      v-html="$t('sfs.form.step2.address1')"
                    ></label>
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="address1"
                  v-model="form.address1"
                  :placeholder="$t('sfs.form.step2.address1')"
                  :title="$t('sfs.form.step2.address1_ex', '-t')"
                  :required="form.state.receipt ? 'required' : false"
                  :state="form.ok.address1"
                  @focusout="check('address1')"
                />
              </b-input-group>
            </b-col>
            <b-col class="px-0 px-md-3">
              <b-input-group>
                <template v-slot:prepend>
                  <b-input-group-text>
                    <label
                      for="address2"
                      class="sr-only"
                      v-html="$t('sfs.form.step2.address2')"
                    ></label>
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="address2"
                  v-model="form.address2"
                  :placeholder="$t('sfs.form.step2.address2')"
                  :title="$t('sfs.form.step2.address2_ex', '-t')"
                />
              </b-input-group>
            </b-col>

            <!-- Zip / City -->
            <b-col class="px-0 px-md-3">
              <b-input-group>
                <template v-slot:prepend>
                  <b-input-group-text>
                    <label
                      for="zip"
                      class="sr-only"
                      v-html="$t('sfs.form.step2.zip')"
                    ></label>
                    <label
                      for="city"
                      class="sr-only"
                      v-html="$t('sfs.form.step2.city')"
                    ></label>
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="zip"
                  v-model="form.zip"
                  :placeholder="$t('sfs.form.step2.zip')"
                  :title="$t('sfs.form.step2.zip_ex', '-t')"
                  :required="form.state.receipt ? 'required' : false"
                  :state="form.ok.zip"
                  @focusout="check('zip')"
                />
                <b-form-input
                  id="city"
                  v-model="form.city"
                  :placeholder="$t('sfs.form.step2.city')"
                  :title="$t('sfs.form.step2.city_ex', '-t')"
                  :required="form.state.receipt ? 'required' : false"
                  :state="form.ok.city"
                  @focusout="check('city')"
                />
              </b-input-group>
            </b-col>

            <!-- Country -->
            <b-col class="px-0 px-md-3">
              <b-input-group>
                <template v-slot:prepend>
                  <b-input-group-text>
                    <label
                      for="country"
                      class="sr-only"
                      v-html="$t('sfs.form.step2.country')"
                    ></label>
                  </b-input-group-text>
                </template>
                <b-form-select
                  id="country"
                  v-model="form.country"
                  :required="form.state.receipt ? 'required' : false"
                  class="custom-select"
                >
                  <option
                    v-for="(country, key) in $t('country')"
                    :key="key"
                    :value="key"
                    v-html="`${$t(key, 'flag')} ${country}`"
                  ></option>
                </b-form-select>
              </b-input-group>
            </b-col>

            <!-- French defisc -->
            <p
              v-show="/(FR|GP|GF|RE|MQ|YT|NC|PF|PM|WF)/.test(form.country)"
              class="mt-4 mb-0 fc-g7"
              v-html="$t('sfs.form.step3.defisc_text', calcDefisc())"
            ></p>
          </div>
        </b-card>
      </div>

      <!-- Step 3 ------------------------------------------------------->
      <div id="payment">
        <h3
          class="sr-only"
          v-html="$t('sfs.form.step3.title')"
        ></h3>
        <!-- Payment mode -->

        <b-radio-group
          v-model="form.pay_mode"
          buttons
          button-variant="light text-uppercase"
          class="btn-square"
        >
          <b-radio value="cb">
            <span>
              <i class="fas fa-credit-card d-block"></i>
              <span v-html="$t('sfs.form.step3.cb')"></span>
            </span>
          </b-radio>
          <b-radio value="pp">
            <span>
              <i class="fab fa-paypal d-block"></i>
              <span v-html="$t('sfs.form.step3.pp')"></span>
            </span>
          </b-radio>
        </b-radio-group>

        <!-- Donate -->
        <p class="text-center">
          <b-button
            id="btnVerif"
            type="submit"
          >
            <span
              style="font-size: 24px;"
              v-html="$t('sfs.form.step3.i_give')"
            ></span>
            <br />
            <span v-text="$n(form.don, 'eur')"></span>
            <span
              v-html="$t('sfs.form.step3.now')"
            ></span>
          </b-button>
          <br />
          <a class="small" href="https://soutenir.framasoft.org?mtm_campaign=Dorlotons&mtm_source=Soutenir%20Dorlotons">{{ $t('dd.recurrent') }}</a>
        </p>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
export default {

  data() {
    return {
      form: {
        don: 50,
        society: false,
        nickname: '',
        lastname: ' ', /* = Company name */
        firstname: ' ', /* = SIREN */
        legalStatus: '',
        email: '',
        address1: ' ',
        address2: '',
        zip: ' ',
        city: ' ',
        country: 'FR',
        pay_mode: 'cb',
        pay_ref: this.randomRef(),
        pay_send: '',
        ok: {
          nickname: null,
          lastname: null,
          firstname: null,
          email: null,
          address1: null,
          zip: null,
          city: null,
        },
        state: {
          anonymous: false,
          receipt: false,
        },
      },
    };
  },

  methods: {
    randomRef() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let randomstring = '';
      for (let i = 0; i < 2; i += 1) {
        const rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum, rnum + 1);
      }
      return randomstring + Math.trunc(new Date().getTime() / 1000);
    },

    check(input) {
      const el = document.getElementById(input);
      switch (input) {
        case 'don':
          if (!Number.isNaN(this.form.don)) {
            this.form.don = Number.parseFloat(this.form.don).toFixed(2);
          } else {
            this.form.don = 50;
          }
          break;
        case 'email':
          if (el.validity.valueMissing) {
            this.form.ok.email = false;
            el.setCustomValidity(this.$t('sfs.form.step2.error_empty'));
          } else if (el.validity.typeMismatch) {
            this.form.ok.email = false;
            el.setCustomValidity(this.$t('sfs.form.step2.error_email'));
          } else {
            this.form.ok.email = true;
            el.setCustomValidity('');
          }
          break;
        default:
          if (el) { // /!\ because a company does not have a first name
            if (el.validity.valueMissing) {
              this.form.ok[input] = false;
              el.setCustomValidity(this.$t('sfs.form.step2.error_empty'));
            } else {
              this.form.ok[input] = true;
              el.setCustomValidity('');
            }
          }
          break;
      }
    },

    addFormField(form, key, value) {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', value);

      form.appendChild(hiddenField);
    },

    payRedirection(e) {
      const form = document.createElement('form');
      const url = [
        'https://www.paypal.com/cgi-bin/webscr?',
      ];
      const offPayUrl = [
        'https://soutenir.framasoft.org/offline_paiement.php?payment=',
      ];

      let lg = this.$route.meta.lang.toUpperCase();
      // Choose in available Monetico languages (fallback EN)
      lg = ['DE', 'EN', 'ES', 'FR', 'IT', 'JA', 'NL', 'PT', 'SV'].includes(lg)
        ? lg
        : 'EN';

      const custom = [
        `campaign=${encodeURIComponent(this.$t('host'))}`,
        `nickname=${encodeURIComponent(this.form.nickname)}`,
        `society=${+this.form.society}`,
        `legalStatus=${encodeURIComponent(this.form.legalStatus)}`,
        `anonymous=${+this.form.state.anonymous}`,
        `survey=${+this.form.state.survey}`,
        `receipt=${+this.form.state.receipt}`,
        `lg=${lg}`,
      ];

      this.form.firstname = this.form.firstname.replace(/^$/, ' ');

      switch (this.form.pay_mode) {
        // TPE Monetico / CM-CIC
        case 'cb':
          form.setAttribute('method', 'POST');
          form.setAttribute('action', `https://soutenir.framasoft.org/monetico/before_paiement_${(this.form.monthly ? '2828134' : '2828136')}.php`);

          this.addFormField(form, 'nom', this.form.lastname);
          this.addFormField(form, 'prenom', this.form.firstname);
          this.addFormField(form, 'mail', this.form.email);
          this.addFormField(form, 'adresse1', this.form.address1);
          this.addFormField(form, 'adresse2', this.form.address2);
          this.addFormField(form, 'ville', this.form.city);
          this.addFormField(form, 'cp', this.form.zip);
          this.addFormField(form, 'pays', this.$t(`country.${this.form.country}`));
          this.addFormField(form, 'pays_code', this.form.country);
          this.addFormField(form, 'montant', this.form.don);
          this.addFormField(form, 'texte_libre', encodeURIComponent(custom.join('&')));
          this.addFormField(form, 'lgue', lg);

          document.body.appendChild(form);
          form.submit();
          break;
        // Paypal
        case 'pp':
          if (this.form.monthly) {
            url.push(
              'cmd=_xclick-subscriptions',
              `&item_name=${encodeURIComponent('Framasoft (don récurrent mensuel)')}`,
              `&a3=${this.form.don}`,
              '&p3=1',
              '&t3=M',
              '&src=1',
              '&no_note=1',
              '&no_shipping=1',
            );
          } else {
            url.push(
              'cmd=_donations',
              `&amount=${this.form.don}`,
              `&item_name=${encodeURIComponent('Framasoft (don ponctuel)')}`,
            );
          }
          url.push(
            '&business=6HSVUPKRDAGC2', // Framasoft Paypal ID
            `&return=${encodeURIComponent(`https://soutenir.framasoft.org/${this.$t('lang')}/merci`)}`,
            '&currency_code=EUR',
            '&address_override=1', // Prefill Paypal’s form
            '&charset=utf-8',
            `&last_name=${encodeURIComponent(this.form.lastname)}`,
            `&first_name=${encodeURIComponent(this.form.firstname)}`,
            `&email=${encodeURIComponent(this.form.email)}`,
            `&address1=${encodeURIComponent(this.form.address1)}`,
            `&address2=${encodeURIComponent(this.form.address2)}`,
            `&zip=${encodeURIComponent(this.form.zip)}`,
            `&city=${encodeURIComponent(this.form.city)}`,
            `&country=${this.form.country}`,
            `&custom=${encodeURIComponent(custom.join('&'))}`,
          );
          this.form.pay_send = url.join('');
          window.location.href = this.form.pay_send;
          break;
        // Vir + Chq
        default:
          this.form.pay_ref = this.randomRef(); // Donator must copy the ref
          if (this.form.pay_mode === 'vir') {
            this.modal.openVir = true;
            offPayUrl.push(encodeURIComponent(`Virement${this.form.monthly ? ' mensuel' : ''}`));
          } else {
            this.modal.openChq = true;
            offPayUrl.push(encodeURIComponent('Chèque'));
          }
          offPayUrl.push(
            `&lg=${this.$t('lang')}`,
            `&ref=${this.form.pay_ref}`,
            `&lastname=${encodeURIComponent(this.form.lastname)}`,
            `&firstname=${encodeURIComponent(this.form.firstname)}`,
            `&email=${encodeURIComponent(this.form.email)}`,
            `&address1=${encodeURIComponent(this.form.address1)}`,
            `&address2=${encodeURIComponent(this.form.address2)}`,
            `&zip=${encodeURIComponent(this.form.zip)}`,
            `&city=${encodeURIComponent(this.form.city)}`,
            `&country=${this.$t(`country.${this.form.country}`)}`,
            `&amount=${this.form.don}`,
            `&custom=${encodeURIComponent(custom.join('&'))}`,
          );
          this.form.pay_send = offPayUrl.join('');
          fetch(this.form.pay_send)
            .catch(err => console.error(err)); // eslint-disable-line
          break;
      }
      e.preventDefault();
    },

    anonymize() {
      if (!this.form.state.anonymous) {
        this.form.nickname = '';
        this.form.ok.nickname = false;
      } else {
        this.form.nickname = 'Anonymous';
        this.form.ok.nickname = true;
      }
    },

    fillReceiptFields() {
      const fields = ['lastname', 'firstname', 'address1', 'zip', 'city'];
      if (this.form.state.receipt) {
        // Reinit fields
        fields.forEach((field) => {
          this.form[field] = this.form[field].replace(/^ $/, '');
          this.form.ok[field] = null;
        });
        if (this.form.society) this.form.firstname = ' ';
      } else {
        // Fill void fields with space
        // reset input validity (because [required]=false)
        // and set form.ok[field] = true
        fields.forEach((field) => {
          this.form[field] = ' ';
          this.check(field);
        });
      }
    },

    calcDefisc() {
      const defisc = this.form.society ? 0.60 : 0.66;
      return {
        percent: this.$n(defisc, { style: 'percent' }),
        amount: `<b>${this.$n(this.form.don, 'eur')}</b>`,
        defisc: `<b>${this.$n(this.form.don - this.form.don * defisc, 'eur')}</b>`,
      };
    },
  },
};
</script>

<style lang="scss">
  #support {
    --contextual1: var(--f-o5);
    --contextual2: var(--f-o6);

    h2 {
      background: var(--f-o6);
      border-radius: .5rem;
      color: #fff;
      font-family: 'Tovari Sans', sans-serif;
      font-size: 2.4rem;
      @media (min-width: 576px) {
        font-size: 3rem;
      }
      font-style: normal;
      line-height: 2.5rem;
      padding: 1.25rem 1.75rem .5rem;
      text-align: center;
      text-transform: uppercase;
      transform: rotate(-2deg);
      width: fit-content;
    }

    h3 {
      color: var(--f-g10);
    }

    .btn-square {
      // border-bottom: 1px dashed var(--contextual1);
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0;
      padding: .5rem 0;
      width: 100%;

      label {
        background: none;
        border: none;
        display: flex;
        margin: 0 0 .5rem;
        padding: 0;
        width: 100%;
        @media (min-width: 576px) {
          max-width: calc(50% - 1rem);
        }

        i {
          color: var(--f-d6) !important;
        }

        > span {
          border: 1px solid var(--f-g2);
          border-bottom-width: 3px;
          padding: .5rem;
          height: 100%;
          width: 100%;
        }

        &:hover, &:focus {
          > span {
            border-color: var(--f-g4)
          }
        }
      }

      .active {
        position: relative;

        i { color: var(--contextual2) !important; }

        > span {
          background: linear-gradient(
            45deg,
            #fff calc(100% - 1.25rem),
            var(--contextual2) 1.25rem);
          border-color: var(--contextual2);
        }

        &:hover, &:focus {
          > span {
            border-color: var(--contextual2);
          }
        }

        &::before {
          background-image:
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: top .15rem right .15rem;
          background-size: .75rem .75rem;
          content: '';
          height: 100%;
          position: absolute;
          width: 100%;
        }
      }
    }

    .modal-header {
      background: var(--f-o6);
      border-bottom: none;

      h2 {
        margin: 0;
        padding: .5rem 0 0 1.5rem;
        transform: none;
      }

      .close {
        color: #fff;
        opacity: 1;
        text-shadow: none;
      }
    }
  }

  #howmuch {
    .active, :active {
      box-shadow: none;
    }

    .input-group .input-group-prepend .input-group-text {
      border-color: #fff;
    }

    .input-group .input-group-text {
      background: #fff;
    }

    .input-group .form-control,
    .input-group .input-group-text {
      border: 2px solid #eee;
      border-radius: 0;
    }

    .amount {
      line-height: 2em;
      padding: 1em 0;

      .input-group {
        height: 52.8px;
        input {
          margin-left: -1px;
        }

        div,
        input {
          font-size: 1.2rem;
          box-shadow: none;
          height: auto;
          border-radius: 0;
        }
      }

      .input-group .input-group-prepend .input-group-text {
        border-color: #eee;
      }
    }
  }

  .btn-group {
    outline: 0;
  }

  #whoAreYou {
    .card {
      background: var(--f-o2);
    }

    .btn-group {
      .active, :active {
        box-shadow: none;
      }

      i {
        color: #757575;
      }
    }

    .input-group-prepend .input-group-text {
      color: #757575;
      background: #fff;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      border-bottom-color: #fff;
      border-radius: 0;
      min-width: 44px;
    }

    input, select {
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      // width: 100%;
      border-radius: 0;
      box-shadow: none;
    }

    #address1 {
      border-bottom-style: dashed;
    }

    #email,
    #nickname,
    #country {
      border-bottom-color: #fff;
    }

    #nickname[readonly="readonly"] {
      border-bottom-color: #e9ecef;
    }

    #firstname, #city {
      border-left: 1px solid #ccc;
    }
  }

  #whoAreYou {
    .btn.active {
      span { border-bottom: 3px solid var(--contextual2); }
      i { color: var(--contextual2); }
    }
  }

  #payment{
    .active, :active {
      box-shadow: none;
    }
  }

  #btnVerif {
    background-color: var(--contextual2);
    border: 1px solid var(--contextual2);
    color: #fff;
    font-size: 1.75rem;
    margin: 1rem 0;
    padding: .75rem 2.5rem;

    &:hover, &:focus {
      opacity: 0.9;
    }
  }
</style>
