<template>
  <div class="dd">
    <intro />
    <what />
    <how />
    <faq />
    <sfs />
    <detail />
  </div>
</template>

<script>
import intro from './Intro.vue';
import what from './What.vue';
import how from './How.vue';
import faq from './FAQ.vue';
import sfs from '../../components/ModalSFS.vue';
import detail from '../../components/ModalDetail.vue';

export default {
  components: {
    intro,
    what,
    how,
    faq,
    sfs,
    detail
  },

  data() {
    // Init count and donators
    const donators = {};

    return {
      hash: window.location.hash,
      start: '2024-06-12',
      end: '2024-07-10',
      days: 0,
      hours: 0,
      progress: {
        total: 0,
        max: 60000,
      },
      list: [],
      donators,
      count: 0,
    };
  },

  created() {
    if (!window.vuefsPrerender) {
      fetch('https://soutenir.framasoft.org/dons/2024.json')
        .then(res => res.json())
        .then((data) => {
          this.list = data.list;
          this.updateData();
        })
        .catch((err) => { console.error(err) }); // eslint-disable-line
    }
  },

  methods: {
    updateData() {
      this.days = Math.round(((new Date(this.end)).getTime() - (new Date()).getTime())
        / (1000 * 60 * 60 * 24));
      this.hours = Math.round(((new Date(this.end)).getTime() - (new Date()).getTime())
        / (1000 * 60 * 60));
      // Fill donators
      for (let i = 0; i < this.list.length; i += 1) {
        if (!this.list[i].monthly
          && new Date(this.start) < new Date(this.list[i].date)) {
            this.count += 1;
            this.progress.total += this.list[i].don;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.dd {
  h2 {
    color: var(--f-g10);
    font-family: Tovari Sans,sans-serif;
    font-size: 3rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
}
</style>
