<template>
  <section
    id="f-news"
    ref="f-news"
  >
    <b-container class="px-0">
      <b-row
        id="f-blog"
        class="mx-0 justify-content-center"
      >
        <div class="w-100">
          <h2
            class="mx-auto"
            v-html="$t('dd.blog.label')"
          ></h2>
        </div>
        <b-col
          v-for="(article, index) in articles['fr']"
          :key="index"
          lg="6"
          :class="`px-0 mb-4 ${index > 1 ? 'd-none d-lg-block' : ''}`"
        >
          <div class="p-4">
            <a :href="article.url">
              <img
                alt=""
                class="mb-3"
                :src="article.img"
                style="height: 200px; width: 100%; object-fit: cover"
                loading="lazy"
              />
            </a>
            <p class="small mb-1">
              <i class="fas fa-calendar"></i>
              <time :datetime="article.date">
                {{ article.date.split('T')[0] }}
              </time>
            </p>
            <div>
              <a :href="article.url">
                <span
                  v-html="article.title"
                ></span>
              </a>
              <p
                class="desc"
                v-html="article.desc"
              ></p>
              <a :href="article.url">
                <p class="mt-2 mb-0 text-right">
                  <span class="btn btn-sm btn-outline-warning">
                    <span v-html="$t('dd.blog.read')"></span>
                    <i class="fas fa-chevron-right"></i>
                  </span>
                </p>
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      articles: {
        fr: [],
      },
    };
  },

  created() {
    if (/^fr/.test(this.$t('lang'))) {
      this.getArticles('fr');
    }
  },

  methods: {
    getArticles(lg) {
      const url = 'https://framablog.org/tag/dorlotons-degooglisons/json';

      fetch(url)
        .then(res => res.json())
        .then((data) => {
          data.items.forEach((item) => {
            if (this.articles[lg].length < 4
              && !item.tags.includes('English')) {
              this.articles[lg].push({
                url: item.url + '?mtm_campaign=Dorlotons&mtm_source=Soutenir%20Dorlotons',
                title: item.title,
                desc: this.$t(item.content_html.split('<p><span id="more-')[0], '-t'),
                date: item.date_published,
                img: item.thumbnail,
              });
            }
          });
        })
        .catch((err) => { console.error(err) }); // eslint-disable-line
    },
  },
};
</script>

<style lang="scss">
#f-news {
  padding: 5rem 0 0;
  position: relative;

  h2 {
    background: var(--f-o6);
    border-radius: 0.5rem;
    color: #fff !important;
    font-family: Tovari Sans,sans-serif;
    font-size: 2.4rem !important;
    font-style: normal;
    line-height: 2.5rem !important;
    padding: 1.25rem 1.75rem 0.5rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-2deg);
    width: fit-content;

    @media (min-width: 576px) {
      font-size: 3rem !important;
    }

    margin: -2.25rem auto 1.5rem auto !important;
  }

  #f-blog {
    background: var(--f-g2);
    border-radius: 1.75rem;
    margin: 1rem auto;
    // padding: 1.75rem .25rem;

    > div > div {
      background: var(--f-g2);
      border-radius: 1.75rem;
      padding: .5rem 1.5rem;
      position: relative;
      z-index: 1;
      height: 100%;

      a {
        background-image: none;
        color: var(--f-g9);
        font-size: 1.25rem;
        font-weight: bold;

        &:hover, &:focus {
          text-decoration: none;
        }

        p {
          margin-bottom: .5rem;
        }

        p:last-of-type {
          line-height: 1.2;
        }
      }

      .desc {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
    }

    h3 {
      background: var(--f-o6);
      border-radius: .5rem;
      color: #fff;
      font-family: 'Tovari Sans', sans-serif;
      font-size: 1.5rem;
      margin: -1.5rem auto 1.5rem 0;
      padding: .25rem .75rem 0rem;
      text-align: center;
      text-transform: uppercase;
      transform: rotate(-2deg);
      width: fit-content;
    }

    .small {
      color: var(--f-g7) !important;
    }
  }

  .btn-outline-warning {
    font-weight: bold;
    border: none;
    background: #fff;

    &:hover, &:focus, &:active {
      background: var(--f-o7);
      color: #fff;
    }
  }
}
</style>
