<template>
  <main class="home">
    <dorlotons />
  </main>
</template>

<script>
import dorlotons from './2024/Main.vue';

export default {
  components: {
    dorlotons
  },
};
</script>
