<template>
  <b-modal id="service-detail" hide-footer :title="$t(`dd.service.${service}.title`)" title-tag="h2"
    :visible="`#detail-${service}` === $parent.hash">
    <div>
      <p v-html="$t(`dd.service.${service}.detail`)" />

      <b-button block variant="primary" target="_blank" :href="$t(`dd.service.${service}.link`)" v-if="$t(`dd.service.${service}.link`).startsWith('http')">
        <b v-html="$t('dd.service.access')" />
        <i class="fas fa-arrow-up-right-from-square" />
      </b-button>
      <b-button block variant="primary" disabled v-else>
        <b v-html="$t('dd.service.soon')" />
      </b-button>

      <b-button block variant="warning" target="_blank" :href="`https://framablog.org?p=34977&mtm_campaign=Dorlotons&mtm_source=Soutenir%20Dorlotons&mtm_content=${service}#${$t(`dd.service.${service}.blog_anchor`)}`">
        <b v-html="$t('dd.service.even_more')" />
        <i class="fas fa-arrow-up-right-from-square" />
      </b-button>
    </div>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      service: 'forms'
    }
  },

  mounted() {
    this.$root.$on('bv::modal::show', (modal) => {
      if (modal.componentId === 'service-detail' && /#detail-/.test(this.$parent.hash)) {
        console.log(this.$parent.hash.replace('#detail-', ''));
        this.service = this.$parent.hash.replace('#detail-', '');
      }
    });
  },

}
</script>
