<template>
  <section id="dd-faq">
    <b-container class="px-md-0">
      <b-row class="justify-content-end justify-content-md-center">
        <b-col cols="12">
          <h2 v-html="$t('dd.faq.title')"></h2>
          <figure
            class="figure"
          >
            <a :href="`${$t('baseurl')}/img/campaign/dd/intro.jpg`">
              <img
                alt=""
                class="figure-img img-fluid rounded"
                :src="`${$t('baseurl')}/img/campaign/dd/intro.jpg`"
              />
            </a>
            <figcaption
              class="figure-caption text-right"
              v-html="$t('dd.faq.credits')"
            ></figcaption>
          </figure>
          <div
            class="accordion my-4"
            role="tablist"
          >
            <b-card
              v-for="index in Object.keys($t('dd.faq.list'))"
              :key="index"
              no-body
              class="mb-2 border-0"
            >
              <b-card-header
                header-tag="header"
                class="p-0 border-0"
                role="tab"
              >
                <h3 class="m-0">
                  <b-button
                    v-b-toggle="`question-${index}`"
                    block
                    variant=" rounded-0 py-3 px-4 text-left"
                  >
                    <b v-html="$t(`dd.faq.list[${index}].q`)"></b>
                  </b-button>
                </h3>
              </b-card-header>
              <b-collapse
                :id="`question-${index}`"
                accordion="dd-faq"
                role="tabpanel"
              >
                <b-card-body class="p-4">
                  <b-card-text>
                    <div v-html="$t(`dd.faq.list[${index}].a`)"></div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <h2 v-html="$t('dd.money.title')"></h2>
          <b-row class="pt-4">
            <b-col lg="4">
              <div
                v-html="$t('sfs.why.money.intro', {
                  percent: $t('metrics.ig-pdonation'),
                  year: $t('metrics.edit').split('/')[0] - 1,
                })"
              ></div>
            </b-col>
            <b-col lg="8">
              <Pie />

              <p
                class="mt-2 text-right small"
                v-html="$t('sfs.why.money.outro', {
                  date: $d(new Date($t('metrics.edit'))),
                })"
              ></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import Pie from '../../../commons/components/sfs/Pie.vue';

export default {
  components: {
    Pie,
  },
};
</script>

<style lang="scss">
#dd-faq {
  background: var(--f-g1);
  padding: 4rem 0;

  .card-header {
    background: var(--f-o2);

    .btn:focus {
      box-shadow: none;
    }
  }

  .money .card {
    border: 3px solid transparent;
    border-image:
      linear-gradient(to left bottom, var(--f-o5), var(--f-g1) 15%, var(--f-g1) 85%, var(--f-o5)) 1;
    background: linear-gradient(to bottom right, var(--f-o2), var(--f-o1));
  }
}
</style>
