<template>
  <div>
    <section
      id="dd-intro"
      class="main-row"
    >
      <b-container class="px-md-0">
        <div class="float-right">
          <I18n />
        </div>
        <b-row class="m-0 mb-5">
          <b-col class="text-center p-0">
            <h1 v-html="$t('dd.intro.title')"></h1>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import I18n from '../../../commons/components/I18n.vue';

export default {
  components: {
    I18n
  }
}
</script>

<style lang="scss">
#dd-intro {
  filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, 0.2));
  padding: 1rem 0 3rem;
  position: relative;

  @media (max-width: 480px) {
    max-height: 600px;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background: var(--f-f7);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
      clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
    }
  }

  &::after {
    background: #daa76e;
    background-image: url('../../../public/img/campaign/dd/intro.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
      clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  h1 {
    color: #fff;
    display: inline-block;
    font-family: "Tovari Sans", sans-serif;
    font-weight: bold;
    margin: 1rem  calc(50% - 300px) 25rem auto;
    max-width: 400px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    text-shadow: none;

    strong {
      display: inline-block;
      border-radius: 0.5rem;
      background: var(--f-o6);
      font-size: 3rem;
      line-height: 2.75rem;
      padding: 1.25rem 1.75rem 0.5rem;
      transform: rotate(-2deg);
    }

    em {
      background: var(--f-f7);
      border-radius: 0.5rem;
      font-size: 2.25rem;
      line-height: 2.5rem;
      padding: 0.5rem 1.25rem 0;
      position: absolute;
      right: 0;
      bottom: -2rem;
      transform: rotate(-5deg);
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
  }

  @media (max-width: 576px) {
    h1 {
      font-size: calc(2.5vw * 8 / 3);
      margin: 1rem auto 25rem;
      padding-bottom: calc(3vw * 8 / 3);

      strong {
        font-size: calc(4vw * 8 / 3);
        line-height: calc(3.5vw * 8 / 3);
        padding: calc(1.25vw * 8 / 3) calc(1vw * 8 / 3) 0;
      }

      em {
        font-size: calc(3.5vw * 8 / 3);
        line-height: calc(2.75vw * 8 / 3);
        padding: calc(0.75vw * 8 / 3) calc(1vw * 8 / 3) 0;
      }
    }
  }

  h2 {
    color: var(--f-g10);
    font-size: 1.5rem;
    line-height: 1.25;

    @media (max-width: 992px) {
      font-size: 1.75rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }

    &::after {
      display: none;
    }
  }

  #f-i18n {
    button {
      border-color: transparent;
      color: var(--f-d1);

      i::before {
        color: var(--f-g2);
      }
    }
  }
}
</style>
