<template>
  <div>
    <section id="dd-how">
      <b-container class="px-md-0">
        <b-row class="justify-content-end justify-content-md-center">
          <b-col
            lg="8"
          >
          <div class="pb-5">
            <div
              class="px-lg-4"
              v-html="$t('dd.how.md', {
                icon1,
                icon2,
                icon3,
                icon4,
              })"
            ></div>
                </div>
          </b-col>
          <b-col
            class="rewards mb-4"
            sm="6"
            lg="4"
          >
            <b-card
              :class="'mt-2'"
            >
              <b-card-body class="h-100 d-flex flex-column justify-content-between p-0">
                <h4>
                  {{ $t(`dd.service.lab.title`) }}
                </h4>

                <h5 class="h5" v-html="$t(`dd.service.lab.subtitle`)" />
                <p v-html="$t(`dd.service.lab.p`)" />
                <p class="text-center">
                  <img
                    alt=""
                    :src="`${$t('baseurl')}img/campaign/dd/1.png`"
                    loading="lazy"
                  />
                </p>
                <p class="text-center m-0">
                  <b-button
                    v-b-modal.service-detail
                    block
                    variant="warning"
                    @click.prevent="$parent.hash = `#detail-lab`"
                  >
                    <b v-html="$t('dd.service.know_more')"></b>
                  </b-button>
                </p>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col lg="4">
            <p class="text-center">
                  <img
                    alt=""
                    class="pytition-img"
                    :src="`${$t('baseurl')}img/campaign/dd/pytition.jpg`"
                    loading="lazy"
                  />
                </p>
          </b-col>
          <b-col lg="8">
            <div class="pb-5">
            <div
              class="px-lg-4"
              v-html="$t('dd.how.petitions.md', {
                icon1,
                icon2,
                icon3,
                icon4,
              })"
            ></div>
            <b-button
                    block
                    variant="warning"
                    href="https://test.framapetitions.org?mtm_campaign=Dorlotons&mtm_source=Soutenir%20Dorlotons&mtm_content=petitions&mtm_placement=button"
                  >
                    <b v-html="$t('dd.how.petitions.test')"></b>
                  </b-button>
                </div>
          </b-col>
          <!-- Services -->
          <h3
            class="col-12 text-center mt-4"
            v-html="$t('dd.service.title')"
          ></h3>
          <b-col
            v-for="(service, j) in ['forms', 'space', 'aktivisda', 'pdf', 'money', 'draw']"
            :key="service"
            class="rewards mb-4"
            sm="6"
            lg="4"
          >
            <b-card
              :class="'m-0'"
            >
              <b-card-body class="h-100 d-flex flex-column justify-content-between p-0">
                <h4>
                  {{ $t(`dd.service.${service}.title`) }}
                </h4>

                <h5 class="h5" v-html="$t(`dd.service.${service}.subtitle`)" />
                <p v-html="$t(`dd.service.${service}.p`)" />
                <p class="text-center">
                  <img
                    alt=""
                    :src="`${$t('baseurl')}img/campaign/dd/${j + 2}.png`"
                    loading="lazy"
                  />
                </p>
                <p class="text-center m-0">
                  <b-button
                    v-b-modal.service-detail
                    block
                    variant="warning"
                    @click.prevent="$parent.hash = `#detail-${service}`"
                  >
                    <b v-html="$t('dd.service.know_more')"></b>
                  </b-button>
                </p>
              </b-card-body>
            </b-card>
          </b-col>
          <h3
            class="col-12 text-center mt-4"
            v-html="$t('dd.service.more')"
          ></h3>
          <b-col
            v-for="(service, j) in ['pad', 'carbon']"
            :key="service"
            class="sponsor mb-4"
            sm="6"
            lg="5"
          >
            <b-card>
              <b-card-body class="h-100 d-flex flex-column justify-content-between p-0">
                <h4>
                  {{ $t(`dd.service.${service}.title`) }}
                </h4>
                <h5 class="h5" v-html="$t(`dd.service.${service}.subtitle`)"></h5>
                <p v-html="$t(`dd.service.${service}.p`)" />
                <p class="text-center">
                  <b-button
                    v-b-modal.service-detail
                    block
                    variant="warning"
                    @click.prevent="$parent.hash = `#detail-${service}`"
                  >
                    <b v-html="$t('dd.service.know_more')"></b>
                  </b-button>
                </p>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="dd-news" v-if="$t('lang') === 'fr'">
      <b-container class="px-md-0">
        <b-row class="justify-content-end justify-content-md-center">
          <b-col cols="12">
            <f-news />
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import FNews from '../../components/FNews.vue';

export default {
  components: {
    FNews,
  },

  data() {
    return {
      days: (((new Date('6/7/2023')).getTime() - (new Date()).getTime()) / (1000 * 3600 * 24) + 0.5).toFixed(0),
      icon1: '<i class="fas fa-screwdriver-wrench fa-shape-circle fa-border fa-pull-left"></i>',
      icon2: '<i class="fas fa-arrows-rotate fa-shape-circle fa-border fa-pull-left"></i>',
      icon3: '<i class="fas fa-code-pull-request fa-shape-circle fa-border fa-pull-left"></i>',
      icon4: '<i class="fas fa-gift fa-shape-circle fa-border fa-pull-left"></i>',
    };
  },
};
</script>

<style lang="scss">
#dd-news {
  padding: 1rem 0 4rem;
  position: relative;

  &:before {
    background:
      var(--f-b2)
      url('../../../public/img/campaign/dd/bg-clouds.jpg')
      no-repeat
      center;
    background-size: cover;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
#dd-how {
  background:
    var(--f-d1)
    url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="600px" height="600px" viewBox="0 0 100 100"><path d="M5.5 12a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM6 46a2 2 0 10.001-3.999A2 2 0 006 46zM31.5 8a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-3 30a2 2 0 10.001-3.999A2 2 0 0028.5 38zm16-10a2.5 2.5 0 000-5 2.5 2.5 0 000 5zM40 17.5a1 1 0 100-2 1 1 0 000 2zm-10 31a1 1 0 100-2 1 1 0 000 2zm-12.5-25a1 1 0 100-2 1 1 0 000 2zM77.53 24.742a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm-21.5-3.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm31.5 15.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-22.5 11.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm28-38a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-25 3.5a2 2 0 100-4 2 2 0 000 4zm23 35.5a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-37-16a1 1 0 100-2 1 1 0 000 2zM29.5 74a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM8 70.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM39.5 86a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM17 97.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm28-38a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM20 63a2 2 0 10.001-3.999A2 2 0 0020 63zm23 35.5a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-37-16a1 1 0 100-2 1 1 0 000 2zM77.53 74.242a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm-12.5 23.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm14.5-40a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-3 30a2 2 0 100-4 2 2 0 000 4zm14.5 11a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm1.5-21a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-4.5-10.5a1 1 0 100-2 1 1 0 000 2zm-34 15.5a1 1 0 100-2 1 1 0 000 2z" fill="%23e2e8f0" fill-opacity=".3"/></svg>');
  padding: 4rem 0;
  position: relative;

  h2:first-of-type {
    margin-top: 0;
  }

  h3 {
    color: var(--f-b5);
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 1.25rem;
  }

  ul {
    list-style: none;
    padding-left: 0;
    li {
      margin-bottom: 1rem;
      padding-left: 3rem;

      .fas {
        margin-left: -3rem;
        margin-top: .25rem;
      }

      strong, .fas {
        color: var(--f-g10);
      }
    }
  }

  .rewards {
    &:nth-of-type(2) {
      height: 100%;
    }

    h2 {
      background: var(--f-o6);
      border-radius: 0.5rem;
      color: #fff !important;
      display: inline-block;
      font-family: Tovari Sans,sans-serif;
      font-size: 2rem !important;
      font-style: normal;
      line-height: 2rem !important;
      margin: -2.25rem auto .75rem auto !important;
      padding: 1rem 1rem 0.5rem;
      text-align: center;
      text-transform: uppercase;
      /* transform: rotate(-2deg); */
    }

    .card {
      height: 100%;
      background: none;
      border: none;

      > .card-body {
        background: radial-gradient(var(--f-o1), var(--f-o2));
        border: 1px solid var(--f-o3);
        border-radius: .5rem;
      }
    }

    small {
      font-style: italic;
    }

    img {
      max-width: 180px;
      height: 180px;
      border: none;
      background: transparent;
      margin: 0 auto;
    }

    h4, .h5 {
      color: var(--f-o8);
    }

    .h5 {
      font-size: 1.125rem;
    }
  }

  .sponsor {
    .card {
      height: 100%;
      background: none;
      border: none;
      > .card-body {
        background: #fff;
        border: 1px solid var(--f-o3);
      }
    }
  }

  .pytition-img {
    max-width: 280px;
    height: 280px;
    border: none;
    background: transparent;
    margin: 0 auto;
    border-radius: 50%;
  }
}
</style>
